import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routesGlobales: Routes = [
  {
    path: 'antena',
    loadChildren: () => import('./webapps/webapps.module').then(ma => ma.WebappsModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./webadmin/webadmin.module').then(mad => mad.WebadminModule)
  },
  {
    path: '',
    redirectTo: 'antena',
    pathMatch: 'full'
  }
];
